/* You can add global styles to this file, and also import other style files */

html {
  font-size: 16px;
}

.popover-link {
  border-bottom: 1px dashed #c1cace;
  color: #68767f;
  cursor: pointer;
}

.menuIcon {
background-color: transparent;
border: 0;

&:focus {
  border-color: #00668f; // se-primary-500
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  outline: 2px solid #fff;
  padding: 0;
  width: 30px;
  height: 30px;
}
}

// overriding remove-layout-pane mobile overrides in turn
@media (max-width: 480px) {
.pl-pane__container--horizontal {
  &:not(.pl-pane--full-screen):not(.pl-pane--medium):not(.pl-modal__content):not(.sn-side-nav):not(.sn-pane-inner-scroller):not(.pl-side-nav--popover):not(.pl-side-nav) {
    display: flex!important;
    position: absolute;

    div.pl-pane--collapse, div.pl-pane, div.pl-pane__info--full-screen {
      overflow-y: auto!important;
    }
  }

}
}

// overriding ngin bar css
.nb-main::before {
outline: 0px solid #d4d9dc;
}

.app-container > * > se-fe-page {
  border-top: 1px solid #d4d9dc;
}
